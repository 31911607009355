<template>
  <nav
    class="navigation-bar"
    :class="{'navigation-bar--slider': thanks || home}"
  >
    <div class="navigation-bar__logo">
      <SplNuxtLink
        to="/"
        is-external
      >
        <img
          src="~/assets/images/DesktopLogo.svg"
          class="navigation-bar__logo__image"
        >
      </SplNuxtLink>
    </div>

    <div
      v-if="thanks"
      class="navigation-bar__thanks-banner"
    >
      <div class="navigation-bar__thanks-banner__shadow" />
      <!-- <Slider  /> -->
      <div class="navigation-bar__thanks-banner__component">
        <!-- <img
          src="~/assets/images/slider/Slide1.webp"
          class="navigation-bar__thanks-banner__component__image"
        > -->
      </div>
    </div>

    <div
      v-if="home"
      class="navigation-bar__home-banner"
    >
      <div class="navigation-bar__home-banner__shadow" />
      <!-- <Slider  /> -->
      <div class="navigation-bar__home-banner__component">
        <!-- <img
          src="~/assets/images/slider/Slide1.webp"
          class="navigation-bar__home-banner__component__image"
        > -->
      </div>
    </div>

    <div>
      <div
        v-if="menuPrimary.length"
        class="navigation-bar__menu"
      >
        <SplNuxtLink
          v-if="!isBlackFriday && !isChristmas && !isValentines && !isFathersDay && !isMothersDay"
          :to="baseSpaLink"
          is-external
          no-lang
          class="navigation-bar__menu__link"
        >
          <DynLang
            capitalize-first
            by-key="search-spas"
          />
        </SplNuxtLink>
        <SplNuxtLink
          v-if="isBlackFriday"
          :to="promoBlackFridayLink"
          is-external
          no-lang
          class="navigation-bar__menu__link"
        >
          🛍️ BlackFriday
        </SplNuxtLink>

        <SplNuxtLink
          v-if="isChristmas && promoChristmasLink"
          is-external
          no-lang
          :to="promoChristmasLink"
        >
          <div class="navigation-bar__menu__link-flex">
            <GiftIcon
              class="navigation-bar__menu__link-flex__icon"
            />
            <Lang
              class="only-desktop-block"
              capitalize-all
              by-key="christmas-special"
            />
          </div>
        </SplNuxtLink>

        <SplNuxtLink
          v-if="isValentines && promoValentinesLink"
          is-external
          no-lang
          :to="promoValentinesLink"
          class="navigation-bar__menu__link"
        >
          ❤️ <Lang by-key="saint-valentines" />
        </SplNuxtLink>
        <SplNuxtLink
          v-if="isFathersDay && promoFathersDayLink"
          is-external
          no-lang
          :to="promoFathersDayLink"
          class="navigation-bar__menu__link"
        >
          <Lang by-key="fathers-day" /> ❤️
        </SplNuxtLink>
        <SplNuxtLink
          v-if="isMothersDay"
          :to="`/promociones/dia-de-la-madre/`"
          is-external
          class="navigation-bar__menu__link"
        >
          <DynLang
            capitalize-first
            by-key="mothers-day"
          /> ❤️
        </SplNuxtLink>
        <DesktopLinkCollection
          :content="menuPrimary"
          class="navigation-bar__menu__listbox"
        />
        <SplNuxtLink
          is-external
          :to="baseGetAwayLink"
          no-lang
          class="navigation-bar__menu__link"
        >
          <DynLang
            by-key="escapada"
            capitalize-all
            plural
          />
        </SplNuxtLink>
        <a
          class="navigation-bar__menu__link"
          :href="`${domain}/canjear/`"
          target="_blank"
        >
          <DynLang
            capitalize-first
            by-key="redeem-voucher"
          />
        </a>
        <span
          v-if="langSelectorItems.length"
          class="navigation-bar__menu__link-last"
        >
          <DesktopLinkCollection
            :content="langSelectorItems"
            rel-alternate
            no-lang
          />
        </span>
        <div class="navigation-bar__menu__button">
          <a
            href="https://spalopiabusiness.com/spalopia-com-agenda/"
            target="_blank"
          >
            <DynLang
              capitalize-first
              by-key="are-you-spa"
            />
          </a>
        </div>
      </div>
      <div class="navigation-bar__session-location">
        <SessionLocationSelector />
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import GiftIcon from '@/core/components/shared/icons/Gift.icon.vue'

import DesktopLinkCollection from '../shared/link-collection/DesktopLinkCollection.vue'
import SplNuxtLink from '../shared/SplNuxtLink.vue'
import SessionLocationSelector from './session-location/SessionLocationSelector.vue'

import type { MenuItem } from '~/core/ts/entity/MenuItem'

import config from '@/core/ts/server-config'
import { useLocatedLinks } from '~/core/composable/shared/useLocatedLinks'

export default defineNuxtComponent({
  components: {
    GiftIcon,
    DesktopLinkCollection,
    SplNuxtLink,
    SessionLocationSelector,
  },
  props: {
    thanks: {
      type: Boolean,
      default: false,
    },
    home: {
      type: Boolean,
      default: false,
    },
    menuPrimary: {
      type: Array as PropType<Array<MenuItem>>,
      default: () => [],
    },
    langSelectorItems: {
      type: Array as PropType<Array<MenuItem>>,
      default: () => [],
    },
    promoBlackFridayLink: {
      type: String,
      default: () => '',
    },
    promoChristmasLink: {
      type: String,
      default: () => '',
    },
    promoValentinesLink: {
      type: String,
      default: () => '',
    },
    promoFathersDayLink: {
      type: String,
      default: () => '',
    },
  },
  setup() {
    const runtime = useRuntimeConfig()
    const domain = runtime.public.domain

    const isBlackFriday = config.promos.isBlackFriday
    const isChristmas = config.promos.isChristmas
    const isFathersDay = config.promos.isFathersDay
    const isMothersDay = config.promos.isMothersDay
    const isValentines = config.promos.isValentines

    // URLs
    const {
      baseSpaLink,
      baseGetAwayLink,
    } = useLocatedLinks()

    return {
      domain,

      isBlackFriday,
      isChristmas,
      isFathersDay,
      isMothersDay,
      isValentines,

      baseSpaLink,
      baseGetAwayLink,
    }
  }
})
</script>

<style lang="scss" scoped>
.inline-link {
  @apply flex;
  @apply items-center;
  @apply gap-2;
}
.navigation-bar {
  @apply flex;
  @apply justify-center;
  @apply w-full;

  @screen lg {
    // @apply max-h-170p;
    height: 170px;
    background-image: url('~/assets/images/DesktopHeader.webp');
    background-size: cover;
  }

  &--slider {
    @apply relative;
    background: transparent;
  }

  &__thanks-banner {
    @apply absolute;
    @apply left-0;
    @apply top-0;
    z-index: -1;
    height: 60vh;
    width: 100%;
    &__component {
      background-image: url('~/assets/images/slider/Slide1.webp');
      background-size: cover;
      height: 100%;
    }
    &__shadow {
      @apply absolute;
      @apply left-0;
      @apply top-0;
      @apply bottom-0;
      @apply right-0;
      --blur: 10em;
      --size: 14em;
      --color: theme('colors.spl-primary-dark');
      --spread: -4em;

      box-shadow: 0 var(--size) var(--blur) var(--spread) var(--color) inset,
        0 calc((var(--size) - 4em) * -1) var(--blur) var(--spread) var(--color) inset;
    }
  }

  &__home-banner {
    @apply absolute;
    @apply left-0;
    @apply top-0;
    z-index: -1;
    height: 381px;
    width: 100%;
    &__component {
      background-image: url('~/assets/images/slider/Slide1.webp');
      background-size: cover;
      height: 100%;

      background-color: rgba(20, 20, 20, 0.4);
      background-blend-mode: darken;
    }
    &__shadow {
      @apply absolute;
      @apply left-0;
      @apply top-0;
      @apply bottom-0;
      @apply right-0;
      --blur: 10em;
      --size: 14em;
      --color: theme('colors.spl-dark');
      --spread: -4em;

      box-shadow: 0 var(--size) var(--blur) var(--spread) var(--color) inset,
        0 calc((var(--size) - 4em) * -1) var(--blur) var(--spread) var(--color) inset;
    }
  }

  &__logo {
    @apply z-10;
    @apply transition-all duration-300 ease-in-out;
    @apply mr-1;
    // margin-left: 5vw;

    // @screen xl {
    //   margin-left: 12vw;
    // }

    &__image {
      width: 12rem;
    }
  }

  &__menu-wrapper {
    @apply flex-grow;
  }

  &__menu {
    @apply flex;
    @apply flex-grow;
    @apply items-center;
    @apply justify-start;
    @apply h-2/6;
    @apply border-b;
    @apply border-white;
    @apply border-opacity-20;
    @apply border-solid;
    @apply py-10p;
    @apply transition-all duration-300 ease-in-out;

    @apply font-bold;

    &__shopping-cart {
      @apply transition-all duration-300 ease-in-out;
    }

    &__listbox {
      @apply flex items-center;
      @apply text-white;
      @apply transition-all duration-300 ease-in-out;
      @apply font-semibold;
      height: 95%;
    }

    a {
      @apply text-white;
    }

    &__link, &__link-flex, &__link-last {
      padding-top: 0.68rem;
      padding-bottom: 0.68rem;

      @apply text-white;

      @apply px-4;
    }

    &__link, &__link-flex {
      @apply border-r-2;
      @apply border-white;
      @apply border-opacity-20;
      @apply border-solid;
    }

    &__link-flex {
      @apply flex;

      &__icon {
        @apply mt-2.5p mr-5p;
      }
    }

    &__button {
      @apply flex items-center justify-center;
      @apply mr-50p;
      @apply ml-4;
      @apply px-4;
      @apply py-2;

      @apply bg-spl-acent-dark;

      @apply rounded-4xl;
      @apply border-1 border-solid;
      @apply border-spl-acent-dark;

      @apply whitespace-nowrap;
    }

    &__button:hover {
      @apply border-white;
    }
  }

  &__session-location {
    @apply font-bold;
    @apply text-white;
    @apply px-2 py-2;
  }
}

</style>
