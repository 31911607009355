<template>
  <div class="main-menu">
    <div class="only-desktop-block">
      <MainMenuDesktop
        :lang-selector-items="langSelectorItems"
        :thanks="thanks"
        :home="home"
        :menu-primary="menuPrimary"
        :promo-black-friday-link="promoBlackFridayLink"
        :promo-christmas-link="promoChristmasLink"
        :promo-valentines-link="promoValentinesLink"
        :promo-fathers-day-link="promoFathersDayLink"
      />
    </div>
    <div class="only-mobile">
      <MainMenuMobile
        :lang-selector-items="langSelectorItems"
        :menu-primary="menuPrimary"
        :menu-secondary="menuSecondary"
        :promo-black-friday-link="promoBlackFridayLink"
        :promo-christmas-link="promoChristmasLink"
        :promo-valentines-link="promoValentinesLink"
        :promo-fathers-day-link="promoFathersDayLink"
      />
    </div>

    <div :class="{'main-finder__wrapper': finderMarginUp}">
      <slot name="before-finder" />
      <div
        v-if="withFinder"
        class="main-finder"
      >
        <MainFinder :default-selected-tab="home ? 'spa' : ''" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import MainMenuDesktop from './MainMenuDesktop.vue'
import MainMenuMobile from './MainMenuMobile.vue'

import MainFinder from '../main-finder/MainFinder.vue'

import esMenuPrimary from './content/es-menu-primary.json'
import ptMenuPrimary from './content/pt-menu-primary.json'

import esMenuSecondary from './content/es-menu-secondary.json'
import ptMenuSecondary from './content/pt-menu-secondary.json'

import { useLangSelector } from '~/core/composable/shared/useLangSelector'
import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'

import { translate } from '~/core/ts/util/translate'
import { isADynamicURL } from '~/core/ts/util/string'
import { useLocatedLinks } from '~/core/composable/shared/useLocatedLinks'
import config from '~/core/ts/server-config'

export default defineNuxtComponent({
  components: {
    MainMenuDesktop,
    MainMenuMobile,
    MainFinder,
  },
  props: {
    thanks: {
      type: Boolean,
      default: false,
    },
    home: {
      type: Boolean,
      default: false,
    },
    withFinder: {
      type: Boolean,
      default: false
    },
    // Only desktop
    finderMarginUp: {
      type: Boolean,
      default: false
    },
  },
  async setup() {
    const route = useRoute()

    const runtime = useRuntimeConfig()

    const {
      generateLangMenuItemsFromURL,
      generateLangMenuItems,
    } = useLangSelector()

    const menuPrimary = translate({
      es: esMenuPrimary,
      pt: ptMenuPrimary,
    })
    const menuSecondary = translate({
      es: esMenuSecondary,
      pt: ptMenuSecondary,
    })

    async function fetchOrGenerateLangMenuItems() {
      if (isADynamicURL(route.path)) {
        return generateLangMenuItemsFromURL(route.path)
      }

      const urlsByLang = await useFetchProxy<Record<string, string>>(
        '/api/shared/get-lang-alter-urls-by-uri',
        {
          // server: false,
          method: 'post', body: { uri: route.path }
        }
      )

      return generateLangMenuItems(urlsByLang)
    }

    const {
      promoBlackFridayLink,
      fetchChristmasPromo,
      promoChristmasLink,

      fetchValentinesPromo,
      promoValentinesLink,

      fetchFathersDayPromo,
      promoFathersDayLink,
    } = useLocatedLinks()

    if (config.promos.isChristmas) {
      await fetchChristmasPromo()
    }

    if (config.promos.isValentines) {
      await fetchValentinesPromo()
    }

    if (config.promos.isFathersDay) {
      await fetchFathersDayPromo()
    }

    const langSelectorItems = await fetchOrGenerateLangMenuItems()

    return {
      menuPrimary,
      menuSecondary,

      langSelectorItems,

      promoBlackFridayLink,
      promoChristmasLink,
      promoValentinesLink,
      promoFathersDayLink,
    }
  }
})
</script>

<style lang="scss" scoped>
.main-menu {
  @apply bg-transparent;
}
.main-finder {
  &__wrapper {
    @screen lg {
      @apply pt-2;
      @apply -mt-28;
    }
  }
}

</style>
